<template>
    <div class="article">
        <div class="title">{{addForm.article_title}}</div>
        <div class="contents" v-html="msg"></div>
    </div>
</template>
<script>
import axios from 'axios'
const baseurl = process.env.VUE_APP_BASE_API
export default {
  name: 'async',
    metaInfo () {
      return {
        title: `${this.addForm.article_title}-沈阳欧米奇`,
        meta: [{                 // set meta
            name: 'keywords',
            content: this.addForm.article_keywords
        },{                 // set meta
            name: 'description',
            content: this.addForm.article_description
        }]
      }
  },
  data(){
    return{
      addForm:{
					article_title:'',
          article_keywords:'',
          article_description:'',
          article_click:''
			},
      artile_type_list:[],
      artile_type: '',
      msg:'',
      articleId :this.$route.params.aid
    }
  },
  methods:{
    
  },
  mounted (){
      axios({
          method: 'post',
          url: `${baseurl}/api/edit`,
          data:{
            "id" : this.articleId
          }
      }).then(res => {
        if(res.data.code==2000)
        {
            this.addForm.article_title = res.data.data.title;
            this.addForm.article_click = res.data.data.click;
            this.artile_type = res.data.data.mid;
            this.msg = res.data.data.contents;
            
        }
        else{
            alert(res.data.msg);
        }
    });
          axios({
                method: 'post',
                url: `${baseurl}/api/clickAdd`,
                data:{
                  "id" : this.articleId,
                }
            }).then(res => {
              if(res.data.code==2000)
              {
                  this.addForm.article_click = res.data.data
              }
              else{
                  alert(res.data.msg);
              }
          });
    
  }

}
</script>